<template>
  <table :class="['graphic-price-table', typeTable]">
    <thead class="graphic-price-table-head">
      <tr class="graphic-price-table-row">
        <th
          v-if="!isBuyingInterest"
          class="graphic-price-table-item"
        />

        <th
          v-if="isBuyingInterest"
          class="graphic-price-table-item lg-item"
        >
          {{ $t('components.graphic_price_table.states') }}
        </th>

        <th class="graphic-price-table-item xs-item">
          {{ $t(`components.graphic_price_table.${isBuyingInterest ? 'offers' : 'sale'}`) }}
        </th>

        <th class="graphic-price-table-item xs-item">
          {{ $t('components.graphic_price_table.credits') }}
        </th>

        <th class="graphic-price-table-item lg-item">
          {{ $t(`components.graphic_price_table.${isBuyingInterest ? 'purchase' : 'offers'}`) }}
        </th>

        <th
          v-if="!isBuyingInterest"
          class="graphic-price-table-item lg-item"
        >
          {{ $t('components.graphic_price_table.states') }}
        </th>

        <th
          v-if="isBuyingInterest"
          class="graphic-price-table-item"
        />
      </tr>
    </thead>

    <tbody class="graphic-price-table-body">
      <tr
        v-for="(item, index) in items"
        :key="index"
        class="graphic-price-table-row"
      >
        <td
          v-if="!isBuyingInterest"
          class="graphic-price-table-item bar-graphic-table-item"
        >
          <div class="content-graphic">
            <div
              class="graphic"
              :style="graphPercentage(item.amount)"
            />
          </div>
        </td>

        <td
          v-if="isBuyingInterest"
          class="graphic-price-table-item overlap"
        >
          <span>
            {{ item.states }}
          </span>
        </td>

        <td class="graphic-price-table-item overlap">
          {{ mountValue(item, amountToGraphCellLabel) }}
        </td>
        <td class="graphic-price-table-item overlap">
          {{ item.credits_quantity }}
        </td>
        <td class="graphic-price-table-item overlap">
          {{ mountValue(item, unitValueToGraphCellLabel) }}
        </td>

        <td
          v-if="!isBuyingInterest"
          class="graphic-price-table-item overlap"
        >
          <span>
            {{ item.states }}
          </span>
        </td>

        <td
          v-if="isBuyingInterest"
          class="graphic-price-table-item bar-graphic-table-item"
        >
          <div class="content-graphic">
            <div
              class="graphic"
              :style="graphPercentage(item.amount)"
            />
          </div>
        </td>
      </tr>

      <tr
        v-if="!hasItems"
        class="graphic-price-table-row"
      >
        <td class="graphic-price-table-item overlap">
          0
        </td>
        <td class="graphic-price-table-item overlap">
          0
        </td>
        <td class="graphic-price-table-item overlap">
          0
        </td>
        <td class="graphic-price-table-item bar-graphic-table-item">
          <div class="content-graphic">
            <div class="graphic" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'GraphicPriceTable',
  mixins: [formatMoney],

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    totalItems: {
      type: Number,
      default: 0,
    },

    typeTable: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasItems() {
      return this.items.length > 0;
    },

    isBuyingInterest() {
      return this.typeTable === 'buying-interest';
    },

    unitValueToGraphCellLabel() {
      return this.isBuyingInterest ? 'unit_value' : 'amount';
    },

    amountToGraphCellLabel() {
      return this.isBuyingInterest ? 'amount' : 'unit_value';
    },
  },

  methods: {
    graphPercentage(value) {
      return {
        width: `${(value / this.totalItems) * 100}%`,
      };
    },

    mountValue(item, key) {
      if (key === 'unit_value') return this.formatMoney(item[key]);

      return item[key];
    },
  },
};
</script>
<style lang="scss" scoped>
  .graphic-price-table {
    font-size: 0.8em;
    border-collapse: collapse;
    width: 100%;
    text-align: center;

    .graphic-price-table-head {
      width: 100%;

      .graphic-price-table-row {
        background: $dashboard_background;
        border-radius: 6px;

        .graphic-price-table-item {
          font-weight: bold;
          height: 54px;
          text-align: center;
          padding: 0px 10px;

          &.xs-item {
            width: 5%;
          }

          &.lg-item {
            width: 40%;
          }
        }
      }
    }

    .graphic-price-table-body {
      width: 100%;

      .graphic-price-table-row {
        width: 100%;
        border-radius: 6px;
        color: $color_gray;
        position: relative;

        .graphic-price-table-item {
          padding: 18px 2px;
          color: $color_dark;
          font-weight: bold;

          &.bar-graphic-table-item {
            z-index: 1;
            display: flex;
            padding: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            .content-graphic {
              display: flex;
              align-items: center;
              width: 100%;
            }
          }

          &.overlap {
            position: relative;
            z-index: 2;
          }
        }
      }
    }

    &.buying-interest {
      .graphic-price-table-body .graphic-price-table-row .graphic-price-table-item {
        &.bar-graphic-table-item {
          justify-content: flex-end;

          .content-graphic {
            justify-content: flex-end;

            .graphic {
              height: 90%;
              width: 0%;
              border-radius: 4px 0 0 4px;
              background: $color_paper;
            }
          }
        }
      }
    }

    &.credit-offered {
      .graphic-price-table-body .graphic-price-table-row .graphic-price-table-item {
        &.bar-graphic-table-item {
          justify-content: flex-start;

          .content-graphic {
            justify-content: flex-start;

            .graphic {
              height: 90%;
              width: 0%;
              border-radius: 0 4px 4px 0;
              background: $color_plastic;
            }
          }
        }
      }
    }
  }
</style>
