<template>
  <div class="price-table-header">
    <div
      v-for="material in materialTypes"
      :key="material.name"
      :class="['base-header-color', materialSelected, activateSelectedMaterial(material)]"
      @click="emitMaterialType(material.name)"
    >
      {{ material.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'PriceTableHeader',
  props: {
    materialTypeHighlighted: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      materialTypes: [
        {
          name: this.$t('material_type.paper'),
          style: 'paper',
        },
        {
          name: this.$t('material_type.plastic'),
          style: 'plastic',
        },
        {
          name: this.$t('material_type.glass'),
          style: 'glass',
        },
        {
          name: this.$t('material_type.metal'),
          style: 'metal',
        },
      ],
    };
  },

  computed: {
    materialSelected() {
      return this.materialTypes.find((material) => material.name === this.materialTypeHighlighted)
        .style;
    },
  },

  methods: {
    emitMaterialType(materialType) {
      this.$emit('materialTypeToFilter', materialType);
    },

    activateSelectedMaterial(material) {
      if (this.materialTypeHighlighted === material.name) return 'active-material-type';
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
  .price-table-header {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;

    .base-header-color {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      color: $color_gray;
      font-weight: bold;
      width: 100%;
      border-radius: 10px 10px 0 0;
      border-bottom: 10px solid $color_gray;
      font-weight: bold;
      cursor: pointer;

      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }

      &.active-material-type {
        color: $color_light;

        &.paper {
          background: $color_paper;
        }

        &.plastic {
          background: $color_plastic;
        }

        &.glass {
          background: $color_glass;
        }

        &.metal {
          background: $color_metal;
        }
      }

      &.paper {
        border-bottom: 10px solid $color_paper;
      }

      &.plastic {
        border-bottom: 10px solid $color_plastic;
      }

      &.glass {
        border-bottom: 10px solid $color_glass;
      }

      &.metal {
        border-bottom: 10px solid $color_metal;
      }

    }
  }
</style>
