<template>
  <div class="card-buying-interest">
    <div class="title-buying-interest">
      {{ $t('components.card_buying_interest.title') }}
    </div>

    <form-buying-interest
      :states="states"
      @createBuyingInterest="create"
    />

    <table-buying-interest
      :items="items"
      @removeBuyingInterest="remove"
    />

    <div
      v-if="!hasItems"
      class="text-center mt-4"
    >
      {{ $t('components.tables.table_buying_interest.no_items') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormBuyingInterest from '@/components/forms/market/FormBuyingInterest.vue';
import TableBuyingInterest from '@/components/tables/TableBuyingInterest.vue';

export default {
  name: 'CardBuyingInterest',

  components: {
    FormBuyingInterest,
    TableBuyingInterest,
  },

  props: {
    states: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(['getBuyingInterests']),

    hasItems() {
      return this.items.length > 0;
    },
  },

  methods: {
    create(payload) {
      this.$emit('createBuyingInterest', payload);
    },

    remove(id) {
      this.$emit('removeBuyingInterest', id);
    },
  },

};
</script>

<style lang="scss" scoped>
.card-buying-interest {
  width: 100%;
  max-width: 900px;
  border-radius: 10px;
  background: $color_white;
  padding: 32px 34px;
  margin-right: 20px;

  .title-buying-interest {
    font-size: 1.2em;
    font-weight: bold;
    color: $color_dark;
  }
}

@media(max-width: 1440px) {
  .card-buying-interest {
    margin: auto;
    margin-bottom: 20px;
  }
}
</style>
