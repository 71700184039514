<template>
  <div class="market">
    <div class="header-market">
      <back-button
        :text="$t('views.market.button.back')"
        @click="goTohome()"
      />

      <span class="page">{{ $t('views.market.title') }}</span>

      <cart-button />
    </div>

    <div class="market-content">
      <card-buying-interest
        :states="states"
        :items="buyingInterests"
        @createBuyingInterest="confirmBuyingInterest"
        @removeBuyingInterest="removeBuying"
      />

      <card-price-table
        :states="formatedStates"
        :items="priceTable"
        :material-type-selected="materialTypeSelected"
        @emitMaterialType="filterInfoByMaterialType"
        @emitState="filterPriceTableByState"
      />

      <confirm-buying-interest-dialog
        @fetchPriceTable="fetchPriceTableToGraphic"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CartButton from '@/components/buttons/CartButton.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import CardBuyingInterest from '@/components/cards/market/CardBuyingInterest.vue';
import CardPriceTable from '@/components/cards/market/CardPriceTable.vue';
import ConfirmBuyingInterestDialog from '@/components/dialogs/ConfirmBuyingInterestDialog.vue';

export default {
  name: 'Market',
  components: {
    CartButton,
    BackButton,
    CardBuyingInterest,
    CardPriceTable,
    ConfirmBuyingInterestDialog,
  },

  data() {
    return {
      showConfirmationDialog: false,
      buyingInterestsToCreate: {},
      describe: {},
      stateSelected: null,
      materialTypeSelected: 'Papel',
    };
  },

  computed: {
    ...mapGetters({
      states: 'getBrazilianStates',
      business: 'getBusiness',
      buyingInterests: 'getBuyingInterests',
      priceTable: 'getPriceTable',
    }),

    formatedStates() {
      return [this.$t('views.market.all'), ...this.states];
    },
  },

  mounted() {
    this.searchState();
    this.indexBuyingInterest({ business_id: this.business.id });

    this.fetchPriceTableToGraphic();
  },

  methods: {
    ...mapActions([
      'searchState',
      'indexBuyingInterest',
      'removeBuyingInterest',
      'fetchPriceTable',
      'changeBuyingInterestDialog',
      'setBuyingInterestToState',
    ]),

    fetchPriceTableToGraphic() {
      this.fetchPriceTable({ material_type: this.materialTypeSelected, state: this.stateSelected });
    },

    async removeBuying(id) {
      await this.removeBuyingInterest({ business_id: this.business.id, id });

      this.fetchPriceTableToGraphic();
    },

    confirmBuyingInterest(params) {
      this.setBuyingInterestToState(params);

      this.changeBuyingInterestDialog(true);
    },

    filterPriceTableByState(state) {
      this.stateSelected = state;

      this.fetchPriceTableToGraphic();
    },

    filterInfoByMaterialType(materialType) {
      this.materialTypeSelected = materialType;

      this.fetchPriceTableToGraphic();
    },
  },

};
</script>

<style lang="scss" scoped>
.market {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  padding: 0px 2em 4em 16em;
  flex-direction: column;

  .header-market {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 40px 0 40px 0;

    .page {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .market-content {
    display: flex;
    justify-content: space-between;
  }
}

@media(max-width: 1440px) {
  .market {
    .market-content {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

</style>
