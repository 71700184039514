<template>
  <table class="table-item-buying-interest">
    <tr
      v-for="item in items"
      :key="item.id"
      class="row-item-buying-interest"
    >
      <td class="item-buying-interest">
        <component
          :is="getIconByMaterialType(mountMaterial(item.material_type_name), false)"
          class="material-type-img mr-4"
        />
      </td>
      <td class="item-buying-interest">
        <span>
          {{
            $t('components.tables.table_buying_interest.items.credits', {
              value: item.quantity_to_compensate
            })
          }}
        </span>
      </td>
      <td class="item-buying-interest">
        <span>
          {{ formatMoney(item.unit_value) }}
        </span>
      </td>
      <td class="item-buying-interest">
        <span>
          {{
            $t('components.tables.table_buying_interest.items.quantity', {
              to_compensate: item.quantity_to_compensate,
              compensated: item.compensated_quantity
            })
          }}
        </span>
      </td>
      <td class="item-buying-interest">
        <span :class="['status', item.status]">
          {{ $t(`components.tables.table_buying_interest.items.status.${item.status}`) }}
        </span>
      </td>
      <td class="item-buying-interest">
        <v-btn
          class="remove-buying-interest"
          icon
          @click="removeBuyingInterest(item.id)"
        >
          <v-icon
            small
            color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </td>
    </tr>
  </table>
</template>

<script>
import getIconsByMaterialType from '@/mixins/getIconsOfMaterialType';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'TableItemBuyinginterest',

  mixins: [getIconsByMaterialType, formatMoney],

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },

    removeBuyingInterest(id) {
      this.$emit('removeBuyingInterest', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-item-buying-interest {
  border-collapse: collapse;

  .row-item-buying-interest {
    margin-top: 16px;
    background: $color_light;
    border-radius: 6px;
    color: $color_gray;
    height: 80px;
    padding: 10px 0;

    .item-buying-interest {
      width: 20%;
      text-align: center;

      .status {
        &.pending {
          background: $color_background_pending;
          color: $color_pending;
          border-radius: 6px;
          padding: 8px;
          font-weight: bold;
        }

        &.approved {
          background: $color_background_approved;
          color: $color_approved;
          border-radius: 6px;
          padding: 8px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
