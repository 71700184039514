<template>
  <v-form
    class="form-buying-interest"
    @submit.prevent="submit"
  >
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <div class="flex-form">
        <div class="content-input">
          <div class="title-field">
            {{ $t('components.form_buying_interest.credit_quantity') }}
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_buying_interest.label_error.quantity')"
            rules="required"
          >
            <v-text-field
              v-model="creditQuantity"
              type="number"
              background-color="white"
              class="input-with-edge input-register"
              :label="$t('components.form_buying_interest.placeholder.credit_quantity')"
              :error-messages="errors"
              required
              outlined
              solo
            />
          </ValidationProvider>
        </div>

        <div class="content-input">
          <div class="title-field">
            {{ $t('components.form_buying_interest.material_type') }}
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_buying_interest.label_error.material_type')"
            rules="required"
          >
            <v-select
              v-model="materialType"
              :items="materialTypes"
              :placeholder="$t('components.form_buying_interest.placeholder.material_type')"
              :error-messages="errors"
              class="input-with-edge select-material-type"
              background-color="white"
              outlined
            />
          </ValidationProvider>
        </div>
      </div>

      <div class="flex-form">
        <div class="content-input">
          <div class="title-field">
            {{ $t('components.form_buying_interest.state') }}
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_buying_interest.label_error.unit_value')"
            rules="required"
          >
            <v-select
              v-model="state"
              :items="states"
              :placeholder="$t('components.form_buying_interest.placeholder.state')"
              class="input-with-edge select-material-type"
              background-color="white"
              :error-messages="errors"
              outlined
            />
          </ValidationProvider>
        </div>

        <div class="content-input">
          <div class="title-field">
            {{ $t('components.form_buying_interest.unit_value') }}
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_buying_interest.label_error.state')"
            rules="required"
          >
            <v-text-field
              v-model="unitValue"
              v-mask="['#', '#,#', '#,##', '##,##', '###,##']"
              background-color="white"
              class="input-with-edge input-register"
              :label="$t('components.form_buying_interest.placeholder.unit_value')"
              :error-messages="errors"
              required
              flat
              outlined
              solo
            />
          </ValidationProvider>
        </div>
      </div>

      <rectoplus-button
        :text="$t('components.form_buying_interest.button')"
        :disabled="invalid"
        class="button-register-interest"
        @click="createBuyingInterest()"
      />
    </ValidationObserver>
  </v-form>
</template>
<script>
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormBuyingInterest',

  components: { RectoplusButton },

  props: {
    states: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      creditQuantity: '',
      materialType: '',
      unitValue: '',
      state: '',
      materialTypes: ['Papel', 'Plástico', 'Vidro', 'Metal'],
    };
  },

  computed: {
    creditQuantityWithoutMask() {
      const value = this.creditQuantity.split(',');

      if (value.length <= 1) return this.creditQuantity;

      return Number(this.creditQuantity.split(',').join('.'));
    },

    unitValueWithoutMask() {
      return Number(this.unitValue.split(',').join('.'));
    },
  },

  methods: {
    createBuyingInterest() {
      this.$emit('createBuyingInterest', {
        creditQuantity: this.creditQuantityWithoutMask,
        materialType: this.materialType,
        unitValue: this.unitValueWithoutMask,
        state: this.state,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-buying-interest {
  margin-top: 40px;

  .flex-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;

    .content-input {
      width: 100%;

      .title-field {
        color: $color_dark;
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .input-register {
        border-radius: 8px;
      }

      .select-material-type {
        color: $color_primary;
        border-radius: 10px;
        width: 100%;
      }
    }
  }

  .button-register-interest {
    width: 100%;
    margin-bottom: 40px;
    height: 54px;
  }
}
</style>
