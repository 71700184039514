import { render, staticRenderFns } from "./TableItemBuyinginterest.vue?vue&type=template&id=8e3a4abe&scoped=true"
import script from "./TableItemBuyinginterest.vue?vue&type=script&lang=js"
export * from "./TableItemBuyinginterest.vue?vue&type=script&lang=js"
import style0 from "./TableItemBuyinginterest.vue?vue&type=style&index=0&id=8e3a4abe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e3a4abe",
  null
  
)

export default component.exports