<template>
  <div
    class="cart-button"
    @click="goToCart()"
  >
    <div class="cart-icon">
      <div class="number-cart-itens">
        {{ getAmountCartItems }}
      </div>
      <v-icon color="primary">
        mdi-cart-outline
      </v-icon>
    </div>
    <span class="text-cart">
      {{ $t('components.offered.cart') }}
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CartButton',
  computed: { ...mapGetters(['getAmountCartItems']) },
  mounted() {
    this.fetchCartItems();
  },
  methods: {
    ...mapActions(['fetchCartItems']),

    goToCart() {
      this.$router.push({ name: 'cart' });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-button {
  display: flex;
  cursor: pointer;

  .cart-icon {
    display: flex;
    flex-direction: column;
    position: relative;

    .number-cart-itens {
      color: $color_primary;
      font-size: 0.875em;
      font-weight: bold;
      position: absolute;
      bottom: 16px;
      right: 8px;
    }
  }

  .text-cart {
    color: $color_primary;
    font-weight: bold;
  }
}
</style>
