<template>
  <v-col
    v-show="dialog"
    cols="auto"
  >
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-top-transition"
      max-width="600"
    >
      <div class="card-dialog">
        <div class="title-text">
          {{ $t('components.dialogs.buying_interest.title') }}
        </div>

        <div class="confirm-text">
          <p>
            {{
              $t('components.dialogs.buying_interest.credit_quantity', {
                creditQuantity: buyingInterest.creditQuantity
              })
            }}
          </p>
          <p>
            {{
              $t('components.dialogs.buying_interest.material_type', {
                materialType: buyingInterest.materialType
              })
            }}
          </p>
          <p>
            {{
              $t('components.dialogs.buying_interest.unit_value', {
                unitValue: formatMoney(buyingInterest.unitValue)
              })
            }}
          </p>
          <p>
            {{
              $t('components.dialogs.buying_interest.state', {
                state: buyingInterest.state
              })
            }}
          </p>
        </div>
      </div>

      <div class="content-button-dialog">
        <button
          class="button-dialog"
          @click="toggleDialog"
        >
          {{ $t('components.dialogs.buying_interest.buttons.cancel') }}
        </button>

        <button
          class="button-dialog"
          @click="sendConfirmation"
        >
          {{ $t('components.dialogs.buying_interest.buttons.confirm') }}
        </button>
      </div>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'ConfirmBuyingInterestDialog',

  mixins: [formatMoney],

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogBuyingInterest: 'getBuyingInterestDialog',
      buyingInterest: 'getBuyingInterest',
      business: 'getBusiness',
    }),
  },

  watch: {
    dialogBuyingInterest(value) {
      this.dialog = value;
    },
  },

  methods: {
    ...mapActions([
      'changeBuyingInterestDialog',
      'createBuyingInterest',
    ]),

    toggleDialog() {
      this.changeBuyingInterestDialog(!this.dialog);
    },

    async sendConfirmation() {
      this.toggleDialog(false);
      await this.createBuyingInterest({
        business_id: this.business.id,
        state: this.buyingInterest.state,
        quantity_to_compensate: this.buyingInterest.creditQuantity,
        unit_value: this.buyingInterest.unitValue,
        material_type: this.buyingInterest.materialType,
      });
      this.$emit('fetchPriceTable');
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-dialog {
    padding: 40px;
    border-radius: 10px;
    background: $color_light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .title-text {
      color: $color_dark;
      font-size: 1.875em;
      margin-bottom: 30px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
    .subtitle-text {
      width: 100%;
      text-align: center;
      color: $color_secondary;
      font-size: 1.5em;
    }

    .confirm-text {
      text-align: justify;
      color: $color_secondary;
      font-size: 1em;
    }
  }

  .content-button-dialog {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .button-dialog {
      width: 50%;
      border-radius: 10px;
      color: $color_light;
      font-size: 1.5em;
      font-weight: bold;
      background: $color_primary;
      height: 70px;

      &:first-child {
        background: $color_light;
        color: $color_primary;
        border: 2px solid $color_primary;
        margin-right: 20px;

        &:hover {
          background: $color_primary;
          color: $color_light;
        }
      }
    }
  }
</style>
