<template>
  <div class="card-price-table">
    <div class="d-flex justify-space-between">
      <div class="price-table-title">
        {{ $t('components.card_price_table.title') }}
      </div>

      <div class="content-input">
        <v-select
          v-model="state"
          :items="states"
          :placeholder="$t('components.card_price_table.filter_state')"
          class="input-with-edge select-state"
          background-color="white"
          outlined
          @change="emitStateToMarket()"
        />
      </div>
    </div>
    <price-table-header
      :material-type-highlighted="materialTypeSelected"
      @materialTypeToFilter="emitMaterialTypeToMarket"
    />

    <div
      v-if="hasntGraphicContent"
      class="content-graphic"
    >
      <div class="graphic-placeholder">
        {{ $t('components.card_price_table.placeholder') }}
      </div>
    </div>
    <div
      v-else
      class="content-graphic"
    >
      <div class="graphic-content graphic-buying-interest">
        <graphic-price-table
          type-table="buying-interest"
          :items="items.buying_interests"
          :total-items="items.total_buying_interests"
        />
      </div>
      <div class="graphic-content graphic-credit-offered">
        <graphic-price-table
          type-table="credit-offered"
          :items="items.credit_offereds"
          :total-items="items.total_credit_offereds"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PriceTableHeader from '@/components/headers/market/PriceTableHeader.vue';
import GraphicPriceTable from '@/components/tables/GraphicPriceTable.vue';

export default {
  name: 'CardPriceTable',
  components: {
    PriceTableHeader,
    GraphicPriceTable,
  },

  props: {
    states: {
      type: Array,
      required: true,
    },

    items: {
      type: Object,
      default: () => ({}),
    },

    materialTypeSelected: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      state: null,
    };
  },

  computed: {
    hasntGraphicContent() {
      return this.items.total_buying_interests === 0 && this.items.total_credit_offereds === 0;
    },
  },

  methods: {
    emitMaterialTypeToMarket(materialType) {
      this.$emit('emitMaterialType', materialType);
    },

    emitStateToMarket() {
      this.$emit('emitState', this.state);
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-price-table {
    width: 100%;
    max-width: 900px;
    border-radius: 10px;
    background: $color_white;
    padding: 32px 34px;

    .price-table-title {
      font-weight: bold;
      font-size: 1.2em;
      color: $color_dark;
    }

    .content-input {
      width: 100%;
      max-width: 200px;

      .select-state {
        border-radius: 10px;
      }
    }

    .content-graphic {
      display: flex;

      .graphic-placeholder {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: bold;
      }

      .graphic-content {
        width: 100%;

        &.graphic-buying-interest {
          background-color: $background_graphic_paper;
        }

        &.graphic-credit-offered {
          background-color: $background_graphic_plastic;
        }
      }
    }
  }
</style>
