<template>
  <table class="table-buying-interest">
    <thead class="header-table-buying-interest">
      <tr class="row-table-buying-interest">
        <th class="item-header-table-buying-interest">
          <span>
            {{ $t('components.tables.table_buying_interest.headers.material') }}
          </span>
        </th>
        <th class="item-header-table-buying-interest">
          <span>
            {{ $t('components.tables.table_buying_interest.headers.credits') }}
          </span>
        </th>
        <th class="item-header-table-buying-interest">
          <span>
            {{ $t('components.tables.table_buying_interest.headers.unit_value') }}
          </span>
        </th>
        <th class="item-header-table-buying-interest">
          <span>
            {{ $t('components.tables.table_buying_interest.headers.quantity') }}
          </span>
        </th>
        <th class="item-header-table-buying-interest" />
        <th class="item-header-table-buying-interest" />
      </tr>
    </thead>

    <tbody
      v-if="hasItems"
      class="body-table-buying-interest"
    >
      <tr class="row-table-buying-interest">
        <td
          class="item-body-table-buying-interest"
          colspan="6"
        >
          <div class="scroll">
            <!--
              O motivo de ter uma table dentro de outra table
              é pq foi a unica maneira de conseguir colocar un scroll no body
            -->
            <table-item-buyinginterest
              :items="items"
              @removeBuyingInterest="removeBuyingInterest"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TableItemBuyinginterest from '@/components/tables/TableItemBuyinginterest.vue';

export default {
  name: 'TableBuyinginterest',
  components: { TableItemBuyinginterest },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasItems() {
      return this.items.length > 0;
    },
  },

  methods: {
    removeBuyingInterest(id) {
      this.$emit('removeBuyingInterest', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-buying-interest {
  font-size: 0.8em;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;

  .header-table-buying-interest {
    .row-table-buying-interest {
      background: $dashboard_background;
      border-radius: 6px;
      height: 50px;

      .item-header-table-buying-interest {
        width: 20%;
      }
    }
  }

  .body-table-buying-interest .row-table-buying-interest .item-body-table-buying-interest {
    .scroll {
      overflow-x: auto;
      height: 200px;
    }
  }
}
</style>
